<template>
  <div class="pb-1">
    <l-table
      ref="lTableRef"
      :module-name="MODULE_NAME"
      :table-columns="tableColumns"
      :table-config-options="{
        endpoint: `${MODULE_NAME}/list`,
      }"
      :row-clicked="navigateToItem"
    >
      <template #cell(order_id)="{data: {item}}">
        {{ getValueFromGivenObjectByKey(item, 'order_id', '—') }}
      </template>
      <template #cell(state)="{data: {item}}">
        <status-filter-table :item="item" />
      </template>
      <template #cell(from)="{data: {item}}">
        {{ getValueFromGivenObjectByKey(item, 'shipping_warehouse_label', '—') }}
      </template>
      <template #cell(scheduled_dispatch)="{data: {item}}">
        {{ getValueFromGivenObjectByKey(item, 'scheduled_dispatch', '—') }} {{ getValueFromGivenObjectByKey(item, 'transfer_time', '—') }}
      </template>
      <template #cell(to)="{data: {item}}">
        {{ getValueFromGivenObjectByKey(item, 'receiving_warehouse_label', '—') }}
      </template>
      <template #cell(items)="{data: {item}}">
        <div class="text-center">
          {{ getValueFromGivenObjectByKey(item, 'items', '—') }}
        </div>
      </template>
      <template #cell(order_date)="{data: {item}}">
        <div class="">
          {{ getValueFromGivenObjectByKey(item, 'created_at', '—') }}
        </div>
      </template>
      <template #table-top-right-side-extras-prev="">
        <status-filter-color-key />
      </template>
    </l-table>
  </div>
</template>

<script>
import LTable from '@/views/components/LTable/LTable.vue'
// eslint-disable-next-line import/no-cycle
import StatusFilterColorKey from '@/views/main/orders/components/use-as-global/StatusFilterColorKey.vue'
// eslint-disable-next-line import/no-cycle
import { getValueFromGivenObjectByKey } from '@core/utils/utils'
import warehouseConfig from '@/views/main/warehouse/main-order/config'
import config from '../config'
import StatusFilterTable from '../components/list/StatusFilterTable.vue'

export default {
  name: 'List',
  components: {
    StatusFilterColorKey,
    StatusFilterTable,
    LTable,
  },
  data() {
    return {
      mappedArray: [],
    }
  },
  computed: {
    transferOrderList() {
      return this.$store.state[this.MODULE_NAME].transferOrderList
    },
  },
  created() {
    // this.$store.dispatch(`${this.MODULE_NAME}/list`).then(res => {
    //   const { data } = res.data.data
    //   // eslint-disable-next-line consistent-return
    //   data.forEach(({
    //     id, assets, order_id, scheduled_dispatch, transfer_time, created_at, receiving_warehouse, items, state, status,
    //   }) => {
    //     if (assets.length) {
    //       assets.forEach(({ from }) => {
    //         this.mappedArray.push({
    //           id, from, order_id, scheduled_dispatch, transfer_time, created_at, receiving_warehouse, items, state, status,
    //         })
    //       })
    //     } else {
    //       this.mappedArray.push({
    //         order_id, scheduled_dispatch, transfer_time, receiving_warehouse, items, state, status,
    //       })
    //     }
    //   })
    //   this.$store.commit(`${this.MODULE_NAME}/LIST`, this.mappedArray)
    // })
  },
  methods: {
    getValueFromGivenObjectByKey,
    navigateToItem({ id, state, status }) {
      if (state === 0 && (status === 5 || status === 4) && state === 0) {
        this.$router.push({ name: 'scheduled-transfer-order-page', params: { id } })
      }
      const orderItem = {
        order_type: this.ORDER_TYPES_KV.TO,
        fulfillment_state: state,
        fulfillment_status: status,
        id,
      }
      if (state >= 1) {
        this.handleOrderStatus(this, orderItem)
      }
    },
  },
  setup() {
    const {
      MODULE_NAME,
      tableColumns,
      orderPurchaseStatesRedirectToPages,
      ORDERS_STATUS_READY_STATUS,
      ORDERS_STATUS_REJECTED,
      ORDERS_STATUS_AS_DRAFT,
      ORDERS_STATE_CLOSED_ORDER,
    } = config()
    const { ORDER_TYPES_KV, handleOrderStatus } = warehouseConfig()

    return {
      MODULE_NAME,
      tableColumns,
      orderPurchaseStatesRedirectToPages,
      ORDERS_STATUS_READY_STATUS,
      ORDERS_STATUS_REJECTED,
      ORDERS_STATUS_AS_DRAFT,
      ORDERS_STATE_CLOSED_ORDER,
      ORDER_TYPES_KV,
      handleOrderStatus,
    }
  },
}
</script>
